import * as React from "react";
import { useState } from 'react';
import {Box,Button,Card,CardContent,CardHeader,Divider,TextField,Typography} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {apiURL} from "../../scenes/constants";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

var url_string = window.location.href
var url = new URL(url_string);
var token = url.searchParams.get("token");
var uidb64 = url.searchParams.get("uidb64");

var urls = apiURL.SET_PW

const SettingsPassword = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  // will be triggered when user click submit to post new password
  const handlePassword = React.useCallback(async (credentials, setSubmitting,setStatus) => {
      //console.log('test',credentials,token,uidb64)
      var data = {
        password: credentials.password,
        token:token,
        uidb64:uidb64
      }

      var result;
      try {
        result =  await axios.patch(urls, data,)
        console.log('Result',result)
      if (result.status == 200) {
        MySwal.fire({
          title: 'Done!',
          text: 'Successfully created password. Please go back to login again.',
          icon: 'success',
          confirmButtonText: 'Go back to login'
        }).then(() => {
          navigate('/login', { replace: true }); 
        });
      }else{
        console.log('wrong!')
        setSubmitting(false);
        setStatus("Something went wrong. Try again.")  
      }      
      } catch (error) {
        console.log('wrong!')
        console.log(error)
        setSubmitting(false);
        setStatus("Something went wrong. Try again.")  
      }
    });

  return (
    <div>
    <Helmet>
        <title>Create password | MES Bakery</title>
    </Helmet>
    <Box sx={{
        justifyContent : 'center',
        //border: 'solid 2px #058473',
        marginLeft : '80px',
        marginRight : '80px',
        marginTop : '50px',
      }}>
    <Formik
          initialValues={{
            password: '',
            confirm:''
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().min(8).required('Password is required'),
            confirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password is required')
          })}
          onSubmit={async(values, { setSubmitting, setStatus, setErrors }) => {
            setSubmitting(true);
            handlePassword(values, setSubmitting, setStatus, setErrors)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status
          }) => (
            <form {...props}>
              <Card>
                <Box sx={{ mb: 2, mt: 2, ml: 3 }}>
                  <Typography color="textPrimary" style={{ fontSize: 36 }}>Password</Typography>
                  <Typography color="textSecondary" gutterBottom style={{ fontSize: 15 }}>Create your new password (Your password must be at least 8 characters and include at least one lowercase letter, one uppercase letter and number.)</Typography>
                </Box>
                
                <Divider />
                <CardContent>
                <Typography color="#d32f2f" variant="h5">
                  {status}
                </Typography>
                  <TextField
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    helperText={touched.confirm && errors.confirm}
                    error={Boolean(touched.confirm && errors.confirm)}
                    fullWidth
                    label="Confirm password"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                  />
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    style={{backgroundColor:'#09a08c', height:'40px', width:'120px' , fontSize:'16px'}}
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Card>
            </form>
          )}
          </Formik>
          </Box>
    </div>
  );
};

export default SettingsPassword;
