import { Box, Typography, Avatar, Tooltip, IconButton, Menu, MenuItem} from "@mui/material";
import  React, { useState , useEffect } from 'react'
import { apiURL } from "../constants";
import axios from 'axios';
import db from '../../firebase-config'
import {collection,onSnapshot,query,where} from "firebase/firestore"
import { useNavigate } from 'react-router-dom';

const Topbar = () => {

  const navigate = useNavigate();
  const options = JSON.parse(localStorage.getItem('settings'))
  //console.log('Setting list = ',options[1])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    //console.log('Event log = ',event.target.innerText)
    switch(event.target.innerText){
      case 'Profile' :
        navigate(`/user-edit?id=${localStorage.getItem('rb_UserId')}`, { replace: true });
        break;
      case 'Account' :
        navigate('/settings', { replace: true })
        break;
      case 'Production' :
        navigate('/production', { replace: true })
        break;
      case 'Logout' :
        localStorage.clear();
        //console.log('Log out')
        navigate('/login', { replace: true })
        break;
      default :
        break;
    }
  };

  const [accumProduct, setAccumProduct] = useState([])
  useEffect(() => {
    const accumProductCollectionRef = query(collection(db,'mes_bakery_current_day_realtime'), where("TYPE", "==", "Accum"))
    const subscriber = onSnapshot(accumProductCollectionRef, snapshot =>{
        setAccumProduct(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()}))) 
    })
        //console.log(accumProduct)
    return () => {
        subscriber()
    }
  }, [accumProduct])

  let factoryDateNow = ''
  for (let i = 0; i<accumProduct.length; i++){
      if (accumProduct !== []) {
        factoryDateNow = accumProduct[i].data.DATE
      }
      //console.log(factoryDateNow)
  } 
  
  var token_url = apiURL.TOKEN;
  // check token
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    if (refresh_exp < Date.now() / 1000) {
      console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      //console.log('redirect to login')
      window.location = "/login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");
      //console.log(localStorage.getItem("rb_rf_token"));
      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };
  
  /*---------------- ดึงข้อมูลจาก API - เวลาการทำงานสะสม ----------------*/
  //FactoryDateData
  const [facDateData, setFacDateData] = useState(
    {
      "FACTORY_DATE": [
          {
              "FACTORY_DATE": " ",
              "STACK_FEEDER_33_1_RUN": " ",
              "STACK_FEEDER_33_1_FAULT": " ",
              "PACKING_SYSTEM_29_RUN": " ",
              "PACKING_SYSTEM_29_FAULT": " ",
              "SPONGE_MIXER_4_RUN": " ",
              "SPONGE_MIXER_4_FAULT": " ",
              "LAST_COUNT_BASK": " ",
              "FIRST_COUNT_BASK": " "
          }
      ]
  }
  )

  useEffect(() => {
    async function fetchDataFacDateData() {
      checkToken()
      var get_FacDateData = apiURL.FactoryDateData + factoryDateNow + '/'
      //console.log(factoryDateNow)
      if (factoryDateNow !== '') {
        await axios.get(get_FacDateData,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("rb_ac_token"),
          },
        })
   
        .then((response) => {
          if (response.status === 200) {
          //console.log(response.data)
          setFacDateData(response.data)}})
        
        .catch((err) => {
           console.log('API Factory date data error:',err.message);
        }); 
      } 
    }
    fetchDataFacDateData()

    const interval = setInterval(() => fetchDataFacDateData(), 1 * 60 * 1000) // Read data every 1 min
    return () => {
      clearInterval(interval);
    }
  },[factoryDateNow])

  var [date,setDate] = useState(new Date());
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    },[date]);

  let now_date = date.toLocaleDateString()
  let now_time = date.toLocaleTimeString()

  function calAccumTime(start,end){
    var startDate = new Date(start);
    var endDate   = new Date(end);
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds
  }
  /*-------------- สำหรับคำนวณเวลาการทำงานสะสม -------------- */
  let acc_now_time = date
  let acc_start_time = facDateData.FACTORY_DATE[0].SPONGE_MIXER_4_RUN
  let acc_end_time = facDateData.FACTORY_DATE[0].LAST_COUNT_BASK
  let work_hour = ''
  let work_min = ''
  if(acc_start_time === ' '){
    work_hour = '-'
    work_min = '-'
  } else if (acc_start_time !== ' ' && acc_end_time === ' '){
    let seconds = calAccumTime(acc_start_time,acc_now_time)
    work_hour = Math.floor(seconds / 3600)
    work_min = Math.floor((seconds - (work_hour * 3600)) / 60)
  } else if(acc_start_time !== ' ' && acc_end_time !== ' '){
    let seconds = calAccumTime(acc_start_time,acc_end_time)
    work_hour = Math.floor(seconds / 3600)
    work_min = Math.floor((seconds - (work_hour * 3600)) / 60)
  }

/*-------------- เวลาเริ่มและสิ้นสุดการผลิต -------------- */
  let start_time = ' '
  let end_time = ' '
  let st_time = facDateData.FACTORY_DATE[0].SPONGE_MIXER_4_RUN  // 1st time Sponge mix 
  let stop_time = facDateData.FACTORY_DATE[0].LAST_COUNT_BASK       // last count basket
  
  if(st_time === ' '){
    start_time = '-'
    end_time = '-'
  } else if (st_time !== ' ' && stop_time === ' '){
    start_time = st_time.substring(11, 19)
    end_time = now_time
  } else if (st_time !== ' ' && stop_time !== ' '){
    start_time = st_time.substring(11, 19)
    end_time = stop_time.substring(11, 19)
  }

  return (
    <Box sx={{ flexGrow: 1}}>
      
            <Box 
              sx={{
                    height: '45px',
                    display:'flex',
                    justifyContent: 'space-around',
                    alignItems:'center',
                    width: '100%',
                    marginTop: '0px', 
                    backgroundColor:'#09a08c' ,
                    
                  }}
            >
                <Typography fontSize='18px' color='white'>{now_date}</Typography>
                <Typography fontSize='18px' color='white'>{now_time}</Typography>
                <Typography fontSize='18px' color='white'>เวลาการทำงานสะสม &nbsp; {work_hour} ชั่วโมง {work_min} นาที</Typography>
                <Typography fontSize='18px' color='white'>เวลาเริ่มและสิ้นสุดการผลิต &nbsp; {start_time} น. ถึง {end_time} น.</Typography>
                <Tooltip title={<Typography color="inherit" fontSize="14px">Open settings</Typography>} arrow placement="bottom">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ height:'30px', width:'30px' }} alt={localStorage.getItem("rb_email").toUpperCase()} src="/broken-image.jpg"/>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                  {options.map((option) => (
                    <MenuItem key={option} onClick={handleClose}>
                        {option}
                    </MenuItem>
                    ))}
                   
              </Menu>
            </Box>
    </Box>
  );
};

export default Topbar;