import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { styled } from '@mui/material/styles';
import StackedBar from "../../components/StackedBar";
import { mockOverallStackedBarData } from "../../data/mockData";
import { mockStackedBarData } from "../../data/mockData";
import { mockSKUYieldData } from "../../data/mockData";
import db from '../../firebase-config'
import React,{useState,useEffect} from "react"
import {collection,onSnapshot} from "firebase/firestore"
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import img_Layout from '../../assets/images/Layout_Bakery.PNG'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SingleRadialChart from "../../components/SingleRadialChart";
import { mockOEEData } from "../../data/mockData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  width: '100%',
  borderRadius:'10px',
}));

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusMachine, setStatusMachine] = useState([])
  const columnHeader = [
    { id: 'sku', label: 'SKU', minWidth: 50 },
    { id: 'yield', label: '% Yield', minWidth: 40 },
    { id: 'std_yield', label: '% STD Yield', minWidth: 40 },
   ]

  useEffect(() => {
      const mcstatusCollectionRef = collection(db,'mes_bakery_last_status')
      const subscriber = onSnapshot(mcstatusCollectionRef, snapshot =>{
          setStatusMachine(snapshot.docs.map(doc => ({id: doc.id, data: doc.data()})))
      })
      // console.log(statusMachine)
      return () => {
          subscriber()
      }

  }, [statusMachine])


  return (
    <div>
      <Box padding = '6px'>
            <Box 
                sx={{
                    height: '40px',
                    backgroundColor: 'rgb(14, 234, 186)',
                    borderRadius: '8px',
                    marginBottom : "8px"
                }}>
                <Typography color='#FFF' fontSize='24px' fontWeight='bold' paddingLeft='20px' paddingTop='2px'>การผลิต : ไลน์ขนมปัง</Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={6} >
                    <Item>
                      <Box 
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                      >
                        {/* SECTION 1 */}
                        {/* HEADER */}
                        <Box
                          gridColumn="span 12"
                          // backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="left"
                          justifyContent="left"
                        >
                          <Typography
                              variant="h5"
                              fontWeight="600"
                              color={colors.grey[100]}
                            >
                              ผลผลิตสะสม
                            </Typography>
                        </Box>
                        {/* ACCUMULATIVE PRODUCTION */}
                        <Box
                          gridColumn="span 12"
                          // backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="left"
                          justifyContent="left"
                          mt='-20px'
                        >
                          <StackedBar
                              textColor={theme.palette.mode==='dark'? '#fff': colors.primary[500]}
                              backgroundColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                              borderColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                              data={mockOverallStackedBarData}
                              height={120}
                              width={700}
                              isLegend={true}
                              isXaxisLabeled={true}
                              alignItems='left'
                              isXaxisTicks={true}
                          />
                        </Box>
                        {/* ACTUAL & TARGET FOR ACCUMULATIVE PRODUCTION */}
                        <Box 
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="right"
                            justifyContent="right"
                            mt='-45px'
                            mr='10px'
                            >
                            <Typography color="black" fontSize={'13px'} align='right'>
                              Actual 20% Target 300 ชิ้น
                            </Typography>
                        </Box>
                        {/* PRODUCTION DETAIL */}
                        <Box 
                            gridColumn="span 12"
                            // backgroundColor={colors.primary[400]}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt='-45px'
                            mr='10px'
                            >
                              <StackedBar
                                textColor={theme.palette.mode==='dark'? '#fff': colors.primary[500]}
                                backgroundColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                borderColor={theme.palette.mode==='dark'? colors.primary[500]: '#fff'}
                                data={mockStackedBarData}
                                height={250}
                                width={500}
                                isLegend={false}
                                isXaxisLabeled={false}
                                is_accumulative={false}
                                alignItems='center'
                                isXaxisTicks={false}
                              />
                        </Box>
                      </Box>
                        
                      {/* SECTION 2 */}
                      <Box 
                        gridColumn="span 12"
                        // backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr='10px'
                      >
                          <Grid container spacing={1}>
                            {/* HEADER */}
                              <Grid item xs={4} >
                                  <Typography fontWeight={'bold'} fontSize='18px' color='black'>ประสิทธิภาพการผลิต</Typography>
                              </Grid>
                              {/* ACTUAL BUN/HOUR */}
                                <Grid item xs={4} >
                                  <Typography bgcolor='lightgrey' color='black'>16,800 ชิ้น/ชม.</Typography>
                              </Grid>
                              {/* STD BUN/HOUR */}
                              <Grid item xs={4} >
                                  <Typography color='black'>(std. 20,000 ชิ้น/ชม.)</Typography>
                              </Grid>
                              <Grid item xs={4} >
                                  
                              </Grid>
                              {/* ACTUAL BUN/HOUR/PERSON */}
                                <Grid item xs={4} >
                                  <Typography bgcolor='lightgrey' color='black'>840 ชิ้น/คน/ชม.</Typography>
                              </Grid>
                              {/* STD BUN/HOUR/PERSON */}
                              <Grid item xs={4} >
                                  <Typography color='black'>(std. 1,000 ชิ้น/คน/ชม.)</Typography>
                              </Grid>
                          </Grid>

                      </Box>
                      {/* SECTION 3 */}
                      <Box 
                          gridColumn="span 12"
                          // backgroundColor={colors.primary[400]}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr='10px'
                        >
                          <Grid container>
                          {/* YIELD */}
                            <Grid item xs={4}>
                              <Stack mt='40px' display='flex' alignItems='center'>
                                {/* ACTUAL YIELD */}
                                  <Typography  textAlign='center' fontWeight={'bold'} fontSize='18px' color='black'>Yield เฉลี่ย</Typography>
                                  <Box sx={{
                                      marginTop:'20px',
                                      height: '50px',
                                      width:'80px',
                                      backgroundColor: 'lightgrey',
                                      borderRadius: '8px',
                                      display: 'flex',
                                      alignItems:'center',
                                      justifyContent: 'center',                   
                                          }}>
                                      <Typography color='black' fontWeight='bold'>98 %</Typography>
                                  </Box>
                                  {/* STD YIELD */}
                                  <Typography marginTop='20px'>(std. 100%)</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={8} mt='20px'>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer  sx={{
                                maxHeight: 150,
                                overflowY: 'scroll'
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {columnHeader.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align={'center'}
                                          style={{ minWidth: column.minWidth }}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {mockSKUYieldData
                                      .map((row) => {
                                        return (
                                          <TableRow hover role="checkbox" tabIndex={-1} key={row.sku}>
                                            {columnHeader.map((column) => {
                                              const value = row[column.id];
                                              return (
                                                <TableCell key={column.id} align={'center'}>
                                                  {value}
                                                </TableCell>
                                                
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                            </Grid>
                          </Grid>
                        </Box>
                      
                    </Item>
                </Grid>
                <Grid item xs={6} >
                    <Item>
                      <Box 
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                      >
                        
                          {
                            mockOEEData.map((item) =>(
                              <Box
                                gridColumn="span 3"
                                // backgroundColor={colors.primary[400]}
                                display="flex"
                                alignItems="left"
                                justifyContent="left"
                                mt={-3}
                              >
                                <SingleRadialChart
                                  key={item.name}
                                  value={item.value}
                                  title={item.name}
                                  width={item.name==='OEE'?'120%':'100%'}
                                  height={item.name==='OEE'?'120%':'100%'}
                                />
                              </Box>
                            ))
                            
                          }
                      </Box>
                      <Box mt={-5} border='1px solid lightgrey' height='500px' borderRadius='10px'>
                          <Typography ml='15px' textAlign='left' fontWeight='bold' fontSize='20px'>Machine Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Stand by &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Running &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fault</Typography>
                          <CircleRoundedIcon style={{position : 'absolute', left:'1052px', top:'297px', fontSize:'20px', color:'lightblue'}}>Stand by status</CircleRoundedIcon>
                          <CircleRoundedIcon style={{position : 'absolute', left:'1168px', top:'297px', fontSize:'20px', color:'lightgreen'}}>Running status</CircleRoundedIcon>
                          <CircleRoundedIcon style={{position : 'absolute', left:'1280px', top:'297px', fontSize:'20px', color:'red'}}>Fault status</CircleRoundedIcon>
                          <img src ={img_Layout} alt="" width ="600px" height="450px"></img>

                          {statusMachine.map((item, index) => {
                              // ----- Status -----
                              let chiller1cold_status = {run : '' , fault :''}
                              let chiller2_status = {run : '' , fault :''}
                              let spongemixer_status = {run : '' , fault :''}
                              let ahu1_status = {run : '' , fault :''}
                              let doughmixer_status = {run : '' , fault :''}
                              let ahu2_status = {run : '' , fault :''}
                              let finalproof_status = {run : '' , fault :''}
                              let oven_status = {run : '' , fault :''}
                              let depanner_status = {run : '' , fault :''}
                              let spiralcooling_status = {run : '' , fault :''}
                              let packing_status = {run : '' , fault :''}
                              let md_status = {run : '' , fault :''}
                              let stackfeeder_status = {run : '' , fault :''}

                              // ----- Color -----
                              let color_chiller1cold_status = ''
                              let color_chiller2_status = ''
                              let color_spongemixer_status = ''
                              let color_ahu1_status = ''
                              let color_doughmixer_status = ''
                              let color_ahu2_status = ''
                              let color_finalproof_status = ''
                              let color_oven_status = ''
                              let color_depanner_status = ''
                              let color_spiralcooling_status = ''
                              let color_packing_status = ''
                              let color_md_status = ''
                              let color_stackfeeder_status = ''

                          for (let i = 0; i < statusMachine.length; i++) {
                              //console.log(statusMachine[i].data.value)
                              // --------------- Chiller 1 -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_1.preparation_room.Brine_chiller_1_RUN'){
                                  chiller1cold_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_1.preparation_room.Brine_chiller_1_FAULT'){
                                  chiller1cold_status.fault = statusMachine[i].data.value
                              }
                              
                              // --------------- Chiller 2 -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_2.preparation_room.Brine_chiller_2_RUN'){
                                  chiller2_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.chiller.chiller_2.preparation_room.Brine_chiller_2_FAULT'){
                                  chiller2_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Sponge mixer -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.sponge_mixer_4.preparation_room.sponge_mixer_4_RUN'){
                                  spongemixer_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.sponge_mixer_4.preparation_room.sponge_mixer_4_FAULT'){
                                  spongemixer_status.fault = statusMachine[i].data.value
                              }

                              // --------------- AHU 1 -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_RUN'){
                                  ahu1_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_1.preparation_room.AHU1_5_1_FAULT'){
                                  ahu1_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Dough mixer -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.final_mixer_7.preparation_room.final_mixer_7_RUN'){
                                  doughmixer_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.mixer.final_mixer_7.preparation_room.final_mixer_7_FAULT'){
                                  doughmixer_status.fault = statusMachine[i].data.value
                              }

                              // --------------- AHU 2 -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_RUN'){
                                  ahu2_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.ahu.ahu_2.preparation_room.AHU2_15_1_FAULT'){
                                  ahu2_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Final proof -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.proofer.proofer_15.preparation_room.proofer_15_RUN'){
                                  finalproof_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.proofer.proofer_15.preparation_room.proofer_15_FAULT'){
                                  finalproof_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Oven -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_RUN'){
                                  oven_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.oven.oven_19.preparation_room.oven_19_FAULT'){
                                  oven_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Depanner -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.depanner.depanner_21.preparation_room.oven_depanner_21_RUN'){
                                  depanner_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.depanner.depanner_21.preparation_room.oven_depanner_21_FAULT'){
                                  depanner_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Spiral cooling -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.spiral_cooler.spiral_cooler_27.packing_room.spiral_cooler_27_RUN'){
                                  spiralcooling_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.spiral_cooler.spiral_cooler_27.packing_room.spiral_cooler_27_FAULT'){
                                  spiralcooling_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Packing -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.packing.pack_29.packing_room.packing_system_29_RUN'){
                                  packing_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.packing.pack_29.packing_room.packing_system_29_FAULT'){
                                  packing_status.fault = statusMachine[i].data.value
                              }
                              
                              // --------------- Metal detector -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.metal_detector.metal_detector_30.packing_room.metal_detactor_30_RUN'){
                                  md_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.metal_detector.metal_detector_30.packing_room.metal_detactor_30_FAULT'){
                                  md_status.fault = statusMachine[i].data.value
                              }

                              // --------------- Stack feeder -----------------
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.stack_feeder_33.packing_room.stack_feeder_33_1_RUN'){
                                  stackfeeder_status.run = statusMachine[i].data.value  
                              }
                              if (statusMachine[i].data.id === 'MES_Bakery.4105.thailand.feeder.stack_feeder_33.packing_room.stack_feeder_33_1_FAULT'){
                                  stackfeeder_status.fault = statusMachine[i].data.value
                              }
                              
                              
                          }

                          // --------------- Chiller 1 -----------------
                          if (chiller1cold_status.run === false && chiller1cold_status.fault === false) {
                              color_chiller1cold_status = 'lightblue'
                          } else if (chiller1cold_status.run === true && chiller1cold_status.fault === false){
                              color_chiller1cold_status = 'lightgreen'
                          }else if (chiller1cold_status.run === false && chiller1cold_status.fault === true){
                              color_chiller1cold_status = 'red'
                          } else {
                              color_chiller1cold_status = 'red'
                          }
                          // --------------- Chiller 2 -----------------
                          if (chiller2_status.run === false && chiller2_status.fault === false) {
                              color_chiller2_status = 'lightblue'
                          } else if (chiller2_status.run === true && chiller2_status.fault === false){
                              color_chiller2_status = 'lightgreen'
                          }else if (chiller2_status.run === false && chiller2_status.fault === true){
                              color_chiller2_status = 'red'
                          } else {
                              color_chiller2_status = 'red'
                          }
                          // --------------- Sponge mixer -----------------
                          if (spongemixer_status.run === false && spongemixer_status.fault === false) {
                              color_spongemixer_status = 'lightblue'
                          } else if (spongemixer_status.run === true && spongemixer_status.fault === false){
                              color_spongemixer_status = 'lightgreen'
                          }else if (spongemixer_status.run === false && spongemixer_status.fault === true){
                              color_spongemixer_status = 'red'
                          } else {
                              color_spongemixer_status = 'red'
                          }
                          // --------------- AHU 1 -----------------
                          if (ahu1_status.run === false && ahu1_status.fault === false) {
                              color_ahu1_status = 'lightblue'
                          } else if (ahu1_status.run === true && ahu1_status.fault === false){
                              color_ahu1_status = 'lightgreen'
                          }else if (ahu1_status.run === false && ahu1_status.fault === true){
                              color_ahu1_status = 'red'
                          } else {
                              color_ahu1_status = 'red'
                          }
                          // --------------- Dough mixer-----------------
                          if (doughmixer_status.run === false && doughmixer_status.fault === false) {
                              color_doughmixer_status = 'lightblue'
                          } else if (doughmixer_status.run === true && doughmixer_status.fault === false){
                              color_doughmixer_status = 'lightgreen'
                          }else if (doughmixer_status.run === false && doughmixer_status.fault === true){
                              color_doughmixer_status = 'red'
                          } else {
                              color_doughmixer_status = 'red'
                          }
                          // --------------- AHU 2 -----------------
                          if (ahu2_status.run === false && ahu2_status.fault === false) {
                              color_ahu2_status = 'lightblue'
                          } else if (ahu2_status.run === true && ahu2_status.fault === false){
                              color_ahu2_status = 'lightgreen'
                          }else if (ahu2_status.run === false && ahu2_status.fault === true){
                              color_ahu2_status = 'red'
                          } else {
                              color_ahu2_status = 'red'
                          }
                          // --------------- Final proof -----------------
                          if (finalproof_status.run === false && finalproof_status.fault === false) {
                              color_finalproof_status = 'lightblue'
                          } else if (finalproof_status.run === true && finalproof_status.fault === false){
                              color_finalproof_status = 'lightgreen'
                          }else if (finalproof_status.run === false && finalproof_status.fault === true){
                              color_finalproof_status = 'red'
                          } else {
                              color_finalproof_status = 'red'
                          }
                          // --------------- Oven -----------------
                          if (oven_status.run === false && oven_status.fault === false) {
                              color_oven_status = 'lightblue'
                          } else if (oven_status.run === true && oven_status.fault === false){
                              color_oven_status = 'lightgreen'
                          }else if (oven_status.run === false && oven_status.fault === true){
                              color_oven_status = 'red'
                          } else {
                              color_oven_status = 'red'
                          }
                          // --------------- Depanner -----------------
                          if (depanner_status.run === false && depanner_status.fault === false) {
                              color_depanner_status = 'lightblue'
                          } else if (depanner_status.run === true && depanner_status.fault === false){
                              color_depanner_status = 'lightgreen'
                          }else if (depanner_status.run === false && depanner_status.fault === true){
                              color_depanner_status = 'red'
                          } else {
                              color_depanner_status = 'red'
                          }
                          // --------------- Spiral cooling -----------------
                          if (spiralcooling_status.run === false && spiralcooling_status.fault === false) {
                              color_spiralcooling_status = 'lightblue'
                          } else if (spiralcooling_status.run === true && spiralcooling_status.fault === false){
                              color_spiralcooling_status = 'lightgreen'
                          }else if (spiralcooling_status.run === false && spiralcooling_status.fault === true){
                              color_spiralcooling_status = 'red'
                          } else {
                              color_spiralcooling_status = 'red'
                          }
                          // --------------- Packing -----------------
                          if (packing_status.run === false && packing_status.fault === false) {
                              color_packing_status = 'lightblue'
                          } else if (packing_status.run === true && packing_status.fault === false){
                              color_packing_status = 'lightgreen'
                          }else if (packing_status.run === false && packing_status.fault === true){
                              color_packing_status = 'red'
                          } else {
                              color_packing_status = 'red'
                          }
                          // --------------- Metal detector -----------------
                          if (md_status.run === false && md_status.fault === false) {
                              color_md_status = 'lightblue'
                          } else if (md_status.run === true && md_status.fault === false){
                              color_md_status = 'lightgreen'
                          }else if (md_status.run === false && md_status.fault === true){
                              color_md_status = 'red'
                          } else {
                              color_md_status = 'red'
                          }
                          // --------------- Stacker feeder -----------------
                          if (stackfeeder_status.run === false && stackfeeder_status.fault === false) {
                              color_stackfeeder_status = 'lightblue'
                          } else if (stackfeeder_status.run === true && stackfeeder_status.fault === false){
                              color_stackfeeder_status = 'lightgreen'
                          }else if (stackfeeder_status.run === false && stackfeeder_status.fault === true){
                              color_stackfeeder_status = 'red'
                          } else {
                              color_stackfeeder_status = 'red'
                          }

                          return (
                              <div key={index}>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1425px', top:'336px', fontSize:'20px', color: color_chiller1cold_status}}>Chiller 1 cold</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1406px', top:'352px', fontSize:'20px', color: color_chiller2_status}}>Chiller 2 </CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1013px', top:'396px', fontSize:'20px', color: color_spongemixer_status}}>Sponge mixer</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'955px', top:'355px', fontSize:'20px', color: color_ahu1_status}}>AHU1</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'938px', top:'483px', fontSize:'20px', color: color_doughmixer_status}}>Dough mixer</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1170px', top:'498px', fontSize:'20px', color: color_ahu2_status}}>AHU2</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1208px', top:'468px', fontSize:'20px', color: color_finalproof_status}}>Final proof</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1075px', top:'682px', fontSize:'20px', color: color_oven_status}}>Oven</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1282px', top:'456px', fontSize:'20px', color: color_depanner_status}}>Depanner</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1406px', top:'525px', fontSize:'20px', color: color_spiralcooling_status}}>Spiral cooling</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1260px', top:'706px', fontSize:'20px', color: color_packing_status}}>Packing</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1288px', top:'734px', fontSize:'20px', color: color_md_status}}>MD</CircleRoundedIcon>
                              <CircleRoundedIcon style={{position : 'absolute', left:'1446px', top:'692px', fontSize:'20px', color: color_stackfeeder_status}}>Stack feeder 33</CircleRoundedIcon>
                              </div>
                          )
                          })}

                      </Box>
                        
                    </Item>
                </Grid>
            </Grid>
            
         </Box> 
    </div>
  );
};

export default Home;