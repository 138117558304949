
export const mockOverallStackedBarData = {
  series: [{
    name: 'Actual',
    data: [50000]
  }, {
    name: 'Remaining',
    data: [50000]
  }
  ],
  category: ['' ]
}

export const mockStackedBarData ={
  series: [{
    name: 'Actual',
    data: [2000, 0, 0, 0, 0, 0, 0]
  }, {
    name: 'Remaining',
    data: [3040, 48000, 43200, 42000, 12960, 17100, 12500]
  }
  ],
  category: ['SKU1-12345678', 'SKU2-12345678', 'SKU3-12345678', 'SKU4-12345678', 'SKU5-12345678', 'SKU6-12345678', 'SKU7-12345678' ]
}

export const mockStackedBarDataNew =[
  {
  series: [{
    name: 'Actual',
    data: [11010],
  }, {
    name: 'Remaining',
    data: [1490]
  }
  ],
  category: ["Hotdog 40g โรยงา"],
  MES_name: ["Hotdog 40g โรยงา"],
  target: [12500],
  production_status : ['Completed']
},{
  series: [{
    name: 'Actual',
    data: [2412],
  }, {
    name: 'Remaining',
    data: [0]
  }
  ],
  category: ["Burger 50g โรยงา"],
  MES_name: ["Burger 50g โรยงา"],
  target: [1596],
  production_status : ['Completed']
},{
  series: [{
    name: 'Actual',
    data: [63300],
  }, {
    name: 'Remaining',
    data: [0]
  }
  ],
  category: ["Burger 50g ไม่โรยงา"],
  MES_name: ["Burger 50g ไม่โรยงา"],
  target: [63204],
  production_status : ['Completed']
},{
  series: [{
    name: 'Actual',
    data: [25530],
  }, {
    name: 'Remaining',
    data: [0]
  }
  ],
  category: ["Hotdog 45g โรยงา (RTE)"],
  MES_name: ["Hotdog 45g โรยงา (RTE)"],
  target: [24000],
  production_status : ['Completed']
},
{
  series: [{
    name: 'Actual',
    data: [91500],
  }, {
    name: 'Remaining',
    data: []
  }
  ],
  category: ["Burger 45g โรยงา"],
  MES_name: ["Burger 45g โรยงา"],
  target: [48000],
  production_status : ['Packing']
},
{
  series: [{
    name: 'Actual',
    data: [0],
  }, {
    name: 'Remaining',
    data: [72000]
  }
  ],
  category: ["Burger 45g โรยงา (RTE)"],
  MES_name: ["Burger 45g โรยงา (RTE)"],
  target: [72000],
  production_status : ['Not start']
},
{
  series: [{
    name: 'Actual',
    data: [0],
  }, {
    name: 'Remaining',
    data: [8684]
  }
  ],
  category: ["Hotdog 35g แช่แข็ง"],
  MES_name: ["Hotdog 35g แช่แข็ง"],
  target: [8684],
  production_status : ['Not start']
},


]

export const mockSKUYieldData =[
  {
    'sku': 'Hotdog 40g โรยงา',
    'actual': 20000,
    'target': 50000,
    'yield': 88,
    'std_yield': 88
  },
  {
    'sku': 'Burger 50g โรยงา',
    'actual': 40000,
    'target': 50000,
    'yield': 151,
    'std_yield': 103
  }
  ,
  {
    'sku': 'Burger 50g ไม่โรยงา',
    'actual': 10000,
    'target': 50000,
    'yield': 100,
    'std_yield': 105
  }
  ,
  {
    'sku': 'Hotdog 45g โรยงา (RTE)',
    'actual': 20000,
    'target': 50000,
    'yield': 106,
    'std_yield': 104
  }
  ,
  {
    'sku': 'Burger 45g โรยงา',
    'actual': 70000,
    'target': 90000,
    'yield': 'NA',
    'std_yield': 103
  }
  ,
  {
    'sku': 'Burger 45g โรยงา (RTE)',
    'actual': 70000,
    'target': 90000,
    'yield': 'NA',
    'std_yield': 103
  }
  ,
  {
    'sku': 'Hotdog 35g แช่แข็ง',
    'actual': 70000,
    'target': 90000,
    'yield': 'NA',
    'std_yield': 77
  }
  ,
  
]

export const mockOEEData =[
  {
    'name': 'OEE',
    'value': 90
  },
  {
    'name': 'AVA',
    'value': 90
  },
  {'name': 'PER',
  'value': 90
  },
  {
    'name': 'QUA',
    'value': 90
  },
]

export const mockStackedBarMarkerData = {
  series: [{
    name: 'Actual',
    data: [
      { x: 'SKU1-12345678',
        y: 20000,
        goals: [
          { name: 'Expected',
            value: 40000,
            
          }
        ]
      },
      { x: 'SKU2-12345678',
        y: 65000,
        goals: [
          { name: 'Expected',
            value: 80000,
            //strokeColor: '#775DD0'
          }
        ]
      }
    
    ]
}]
}
  