import { Helmet } from 'react-helmet';
import {Box,Container,Typography,Button} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const Sent = () => (
  <>
    <Helmet>
      <title>Success link | MES Bakery</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md"       
      sx={{
        marginTop: {
          xs: 4,
        }
      }}>
        <Typography
          align="center"
          color="textPrimary"
          variant="h4"
          fontSize='36px'
        >
          Successfully send reset password link to your email
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          variant="subtitle1"
          fontSize='19px'
          marginTop='10px'
        >
          Please check your inbox. If you have not received a link in 2-3 minutes,
          Try to contact admin.
        </Typography>
        <Box sx={{ textAlign: 'center', marginTop:'40px' }}>
          <AttachEmailIcon sx={{ fontSize: 100 }}/>
        </Box>
        <Box sx={{ py: 2 }}>
          <Button
              component={RouterLink}
              //color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              to="/login"
              style={{backgroundColor:'#09a08c', height:'40px' , fontSize:'14px'}}
            >
              Back to login 
            </Button>
          </Box>
      </Container>
    </Box>
  </>
);

export default Sent;
