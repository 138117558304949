import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import SettingsPassword from '../../components/Settings/SettingsPassword';

const Resetpw = () => (
  <>
    
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ pt: 3 }}>
          <SettingsPassword />
        </Box>
      </Container>
    </Box>
  </>
);

export default Resetpw;
