import React from 'react'
import { Box} from "@mui/material";
import Chart from 'react-apexcharts';

function SingleRadialChart(props) {
  return (
    <Box>
        <Box sx={{ display: 'flex' }}>
        <Chart
            options={{
            chart: {
                type: 'radialBar',
                offsetY: props.title==='OEE'? 0 : 20,
                offsetX: props.title==='OEE'? 0 : 10,
                toolbar: {
                show: false
                }
            },
            plotOptions: {
                radialBar: {
                hollow: {
                    margin: 15,
                    size: "60%",
                },

                dataLabels: {
                    showOn: "always",
                    name: {
                    offsetY: 0,
                    show: true,
                    color: "#0e6dea",
                    fontSize: "20px"
                    },
                    value: {
                    offsetY: 10,
                    color: "#263d5c",
                    fontSize: "17px",
                    show: true
                    }
                }
                }
            },
            labels: [props.title],
            fill: {
                type: "gradient",
                gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: ["#87D4F9"],
                stops: [0, 100]
                }
            },
            }}
            series={[props.value !== '' ? props.value: 'NA']}
            type="radialBar"
            width={props.width}
            height={props.height}
        />
        </Box>
    </Box>
  )
}

SingleRadialChart.propTypes = {

}

export default SingleRadialChart

