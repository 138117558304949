import {Box,Button} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
  
  function UserToolbar(props) {
    return (
      <Box {...props}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop:'18px'
          }}
        >
          <Button
            component={RouterLink}
            to="/register"
            variant="contained"
            //color="primary"
            style={{backgroundColor:'#0466c9', height:'40px' , fontSize:'14px'}}
          >
            Create new account
          </Button>
        </Box>
      </Box>
    );
  }
  
  UserToolbar.propTypes = {};
  
  export default UserToolbar;
  