import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import mainLogo from '../../assets/images/bakeryfactory.png'
import homeLogo from '../../assets/images/home.png'
import rmWarehouseLogo from '../../assets/images/rawmaterial.png'
import productionLogo from '../../assets/images/production.png'
import warehouseLogo from '../../assets/images/warehouse.png'
import maintenanceLogo from '../../assets/images/maintenance.png'
import energyLogo from '../../assets/images/energy.png'
import historyLogo from '../../assets/images/history.png'
import notificationLogo from '../../assets/images/notification.png'
import { tokens } from "../../theme";

const Item = ({ title, to, icon, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
       <List style={{color: colors.grey[100], backgroundColor: '#10d3b9', height: '52px'}}>
        <ListItem key="monitor" disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: 'center',
              px: 2.5,
            }}
            
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr:'auto',
                justifyContent: 'center',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <img src={icon} height="24px" width="24px"/>
              <Typography textAlign='center' fontSize='9px' mt = '4px'>{title}</Typography>
              
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Link to={to} />
      </List>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box 
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          height: "100vh",
          
        },
        "& .pro-sidebar": {
          width: '60px',
          minWidth: '60px'
          
          
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 0px 5px 20px !important",
          
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            // onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <Box
              component="img"
              alt="logo"
              src={mainLogo}
              sx={{
                height: 30,
                width: 30,
              }}
            /> : undefined}
            style={{
              margin: "0 0 15px -8px",
              color: colors.grey[100],
              alignContent:"center"
            }}
          >
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "10%"} mt='-17px'>
            <Typography textAlign='center' color='black' fontSize='11px'>
              Dashboard Bakery
            </Typography>
          </Box>
          <Box paddingLeft={isCollapsed ? undefined : "10%"} m='4px' alignItems='center' alignContent='center'>
            {/* HOME */}
            <Item
              title="Home"
              to="/"
              alignItems='center'
              alignContent='center'
              icon={homeLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* RAW MATERIAL WARHOUSE */}
            <Item
              title="คลังวัตถุดิบ"
              to="/raw-material-warehouse"
              icon={rmWarehouseLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* PRODUCTION */}
            <Item
              title="การผลิต"
              to="/production"
              icon={productionLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* WAREHOUSE */}
            <Item
              title="คลังสินค้า"
              to="/warehouse"
              icon={warehouseLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* MAINTENANCE */}
            <Item
              title="Maintenance"
              to="/maintenance"
              icon={maintenanceLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* ENERGY */}
            <Item
              title="Energy"
              to="/energy"
              icon={energyLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* HISTORY */}
            <Item
              title="History"
              to="/history"
              icon={historyLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider/>

            {/* NOTIFICATION*/}
            <Item
              title="Notification"
              to="/notification"
              icon={notificationLogo}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;