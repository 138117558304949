import React from 'react'
import { useState } from "react";
import { Outlet } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";

function Layout() {

    const [theme, colorMode] = useMode();
  
    return (
      
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Outlet/>
        </ThemeProvider>
      </ColorModeContext.Provider>
    )
  }
  
  export default Layout