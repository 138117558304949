import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {Box,Button,Container,Link,TextField,Typography} from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { apiURL} from "../constants";

var urls = apiURL.LOGIN_URL;

const Login = () => {
  const navigate = useNavigate();

  // จะทำงานเมื่อuser คลิ๊กปุ่ม Login to post credential to server
  const handleLogin = React.useCallback(
    async (credentials, setSubmitting, setStatus) => {
      // define body when post API
      var data = {
        email: credentials.email,
        password: credentials.password
      };

      var result;
      try {

        // post username & password to login API
        result = await axios.post(urls, data);
        if (result.status === 200) {
          // decode token to get expiry and store in localstorage
          localStorage.clear();
          let access_user = jwtDecode(result.data.tokens.access);
          let access_exp = access_user.exp;
          let refresh_user = jwtDecode(result.data.tokens.refresh);
          let refresh_exp = refresh_user.exp;
          //console.log('is_admin ', result.data.is_superuser)
          //console.log('rb_ac_token', result.data.tokens.access) //log to check
          //console.log('rb_ac_exp', access_exp) //log to check
          //console.log('rb_rf_exp', refresh_exp) //log to check
          //console.log('rb_UserId', result.data.id) //log to check
          localStorage.setItem('rb_ac_token', result.data.tokens.access);
          localStorage.setItem('rb_rf_token', result.data.tokens.refresh);
          localStorage.setItem('rb_ac_exp', access_exp);
          localStorage.setItem('rb_rf_exp', refresh_exp);
          localStorage.setItem('rb_UserId', result.data.id);
          localStorage.setItem('rb_Admin', result.data.is_superuser);
          localStorage.setItem('rb_Staff', result.data.is_staff);
          localStorage.setItem('rb_authen', 'True');
          localStorage.setItem('rb_email', result.data.email);
          console.log('login =', `${localStorage.getItem('rb_authen')}`)
          
          if (`${localStorage.getItem('rb_Admin')}` === 'false') 
          {
            localStorage.setItem('settings', JSON.stringify(['Profile', 'Production', 'Logout'])); 
          } else {
            localStorage.setItem('settings', JSON.stringify(['Profile', 'Account', 'Production', 'Logout']));
            //console.log('setting list = ',`${localStorage.getItem('settings')}`)
          }

          // redirect to production page
          navigate('/production', { replace: true });

        } else {
          console.log('wrong!');
          setSubmitting(false);
          // set status when user input incorrect username or password
          setStatus('Invalid credentials, try again');
        }
      } catch (error) {
        console.log('wrong!');
        setSubmitting(false);
        // set status when user input incorrect username or password
        setStatus('Invalid credentials, try again');
      }
    }
  );

  return (
      <>
      <Helmet>
        <title>Login | MES Bakery</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          //border: 'solid 2px #058473'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().min(8).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);
              handleLogin(values, setSubmitting, setStatus);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                    marginTop='5px'
                  >
                    Sign in on the internal MES Bakery platform
                  </Typography>
                </Box>
                <Typography color="#d32f2f" variant="body4">
                  {status}
                </Typography>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2}}>
                  <Button
                    //color="primary"
                    style={{backgroundColor:'#09a08c', height:'50px' , fontSize:'18px'}}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                     
                  >
                    Sign in
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="h5">
                  Forget your password?{' '}
                  <Link
                    component={RouterLink}
                    to="/email"
                    variant="h5"
                    underline="hover"
                    style={{color:'#0466c9'}}
                  >
                    Reset Password
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      </>
      
  );
};

export default Login;
