import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import Email from '../../components/Settings/Email';

const Emailpw = () => (
  <>
    <Helmet>
      <title>Email | MES Bakery</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ pt: 3, mt:'50px' }}>
          <Email />
        </Box>
      </Container>
    </Box>
  </>
);

export default Emailpw;
