import React from 'react'
import { Box, useTheme} from '@mui/material'
import Chart from 'react-apexcharts'
import { tokens } from "../theme";
import { SocialDistance } from '@mui/icons-material';

function StackedBar(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let array1 = props.data.series[0].data
    let array2 = props.data.series[1].data
    let sumArray = array1.map((v, i) => v + array2[i])

  return (
    <>
        <Box 
          sx={{
            display: 'flex',
            height: props.graphHigh,
            alignItems: props.alignItems,
            justifyContent: 'center', 
            //border: 'solid 2px #058473',
            mr: props.marginright,
            ml: props.marginleft
            //marginBottom:'10px'
         }} >
            <Chart
                options= {{
                    chart: {
                      type: 'bar',
                      stacked: true,
                      toolbar: {
                        show: false
                      }
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        dataLabels: {
                            
                            enabled: false,
                            enabledOnSeries: undefined,
                            formatter: function (val, opts) {
                                return val.toFixed(2)
                            },
                            textAnchor: 'start',
                            distributed: false,
                            offsetX: 0,
                            offsetY: 0,
                            
                            total: {
                                enabled: props.targetTotal? true: false,
                                offsetX: 10,
                                style: {
                                fontSize: '12px',
                                fontWeight: 600,
                                alignItems: 'left',
                                color: colors.primary[100]
                                }
                            }
                        }
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: function (val, opts) {
                        return val.toLocaleString()
                    },
                    style: {
                      fontSize: props.datalabel_fontsize,
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      },
                    background: {
                      enabled: true,
                      foreColor: '#696969',
                      opacity: 0,
                      }, 
                    },

                    grid: {
                      show: false,
                      borderColor: '#90A4AE',
                      strokeDashArray: 0,
                      position: 'back',
                      xaxis: {
                          lines: {
                              show: false
                          }
                      },   
                      yaxis: {
                          lines: {
                              show: false
                          }
                      },  
                      row: {
                          colors: undefined,
                          opacity: 0
                      },  
                      column: {
                          colors: undefined,
                          opacity: 0
                      },  
                      
                  }, 
                    stroke: {
                      width: 0,
                      colors: ['#fff']
                    },
                    title: {
                      text: props.title
                    },
                    xaxis: {
                      show: false,
                      tickAmount: 10,
                      //forceNiceScale:true,
                      categories: props.data.category,
                      min: 0,
                      //max: props.isXaxisLabeled? Math.max(...sumArray): Math.max(...sumArray)+7000,
                      max: props.data.target > props.data.series[0].data? props.data.target: props.data.series[0].data,
                      labels: {
                        show: props.isXaxisLabeled,
                        formatter: function (val) {
                          return val.toLocaleString()
                        }
                      },
                      axisTicks: {
                        show: props.isXaxisTicks,
                        tickAmount: 5,
                      },
                    },
                    
                    yaxis: {
                      show: false,
                      title: {
                        
                        text: undefined
                      },
                    },
                    tooltip: {
                      y: {
                        formatter: function (val) {
                          return val.toLocaleString()+ " ชิ้น"
                        }
                      },
                      
                    },
                    fill: {
                      opacity: 1
                    },
                     colors: [ '#00FA9A', 'lightgrey'],
                    legend: {
                      fontSize:'10px',
                      show: props.isLegend,
                      position: 'bottom',
                      horizontalAlign: 'left',
                      offsetX: 190,
                      offsetY: -5
                    }
                  }}
                  // series={data.value != 'NA'? data.value : 0} '#00FA9A'
                series = {props.data.series}
                type="bar"
                width={props.width}
                height={props.height}
                
            />
        </Box>
    </>
  )
}

StackedBar.propTypes = {

}

export default StackedBar

