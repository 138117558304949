import { useState } from "react";
import { Routes, Route, Navigate ,useRoutes} from "react-router-dom";
import Production from "./scenes/production";
import Home from './scenes/home'
import Login from './scenes/login/Login'
import Mainlayout from "./scenes/global/Mainlayout";
import Layout from "./scenes/global/Layout";
import Register from "./scenes/login/Register";
import Settings from "./scenes/login/Settings";
import Email from "./scenes/login/Email";
import Resetpw from "./scenes/login/Resetpw";
import Sent from "./scenes/login/Sent";
import UserEdit from "./components/Settings/UserEdit";

function App() {
  
  const loggedIn = localStorage.getItem('rb_authen')
  const isAdmin = localStorage.getItem('rb_Admin') 
  
  return (
    useRoutes([
      {
          path: "/",
          element: loggedIn === 'True' ? <Mainlayout /> : <Navigate to="/login" />,
          children: [
            { path: "/", element:'' },
            { path: "/home", element: '' },
            { path: "/raw-material-warehouse", element: '' },
            { path: "/production", element: <Production /> },
            { path: "/warehouse", element: '' },
            { path: "/maintenance", element: '' },
            { path: "/energy", element: '' },
            { path: "/history", element: '' },
            { path: "/notification", element: '' },
            { path: "/register", element: isAdmin === 'true' ? <Register /> : '' },
            { path: "/user-edit", element: <UserEdit /> },
            { path: "/settings", element: isAdmin === 'true' ? <Settings /> : '' },
          ],
      },
      {
          path: "/",
          element:  loggedIn !== 'True' ? <Layout /> : <Navigate to="/production" /> ,
          children: [
            { path: "/login", element: <Login /> },
            { path: '/email', element: <Email /> },
            { path: '/reset-pw', element: <Resetpw /> },
            { path: '/sent', element: <Sent /> },
            { path: "/", element: <Navigate to="/login" /> },
          ],
      },
    ])     
  )
}

export default App;